<div class="container">
  <ng-container *ngIf="!loading && !dynamicHtml">
    <div class="text-center">
      <img
        src="//content.wingstop.com/assets/static/wingstop_404_boat1.jpg"
        class="img-fluid" />
      <h1 id="not-found-title">COMING UP EMPTY?</h1>
      <h2>
        AIN’T NOBODY GOT TIME FOR THAT.
        <br />
        GET BACK AT IT
      </h2>
    </div>
  </ng-container>

  <ng-container *ngIf="dynamicHtml">
    <div [innerHTML]="dynamicHtml"></div>
  </ng-container>
</div>
