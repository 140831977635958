<ng-container>
  <ng-container [ngSwitch]="content.name">
    <ng-container *ngSwitchCase="'birthday'">
      <ng-container *ngTemplateOutlet="birthdayTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultTemplate>
  <div class="wing-alert-modal text-center" trapFocus>
    <button
      *ngIf="content.topRightCloseButton"
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()">
      <svg type="close" aria-hidden="true"></svg>
    </button>
    <img *ngIf="content.image" [src]="content.image" alt="" />
    <div class="wing-alert-modal--inner">
      <h2 class="h3">{{ content.title }}</h2>
      <h5 *ngIf="content.subTitle">{{ content.subTitle }}</h5>
      <p *ngIf="content.leadText">{{ content.leadText }}</p>

      <div *ngIf="content.description.length == 1" class="single-message">
        <p [innerHtml]="content.description"></p>
      </div>

      <div *ngIf="content.description.length > 1" class="multiple-messages">
        <ul>
          <li *ngFor="let item of content.description">
            <fa-icon [icon]="icons.faCircle" size="xs"></fa-icon>
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>

      <div
        *ngIf="
          alertState &&
          form.name.errors &&
          (form.name.dirty || form.name.touched)
        "
        class="alert alert-danger">
        <div *ngIf="alertState && form.name.errors?.required">
          Name is required.
        </div>
        <div *ngIf="alertState && !form.name.errors?.maxLength">
          Name must be no more than 24 characters long.
        </div>
      </div>

      <div
        class="input-group mb-3"
        *ngIf="content.hasFormField"
        [formGroup]="favoriteForm">
        <input
          type="text"
          formControlName="name"
          class="form-control"
          placeholder="Name Your Favorite"
          aria-label="Name Your Favorite"
          aria-describedby="basic-addon1"
          #name />
      </div>

      <button
        *ngIf="!content.hasFormField"
        type="button"
        class="btn btn-secondary btn-block"
        [ngClass]="!content.hasCloseButton ? 'mb-2' : ''"
        (click)="agreeWithRules()"
        attr.aria-label="{{ content.buttonAriaLabel }}">
        {{ content.button }}
      </button>

      <button
        *ngIf="content.hasFormField"
        type="button"
        [disabled]="form.name.errors"
        class="btn btn-secondary btn-block"
        (click)="agreeWithRules()"
        attr.aria-label="{{ content.buttonAriaLabel }}">
        {{ content.button }}
      </button>
      <strong class="close-action" *ngIf="content.hasCloseButton">
        <button
          class="btn btn-link btn-block text-center mt-3"
          (click)="close()"
          tabindex="0">
          {{ content.closeButton }}
        </button>
      </strong>
    </div>
  </div>
</ng-template>

<ng-template #birthdayTemplate>
  <div class="wing-alert-modal text-center birthday-modal" trapFocus>
    <button
      *ngIf="content.topRightCloseButton"
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()">
      <svg type="close" aria-hidden="true"></svg>
    </button>
    <img
      src="/assets/images/bday-fries.png"
      alt="birthday fries"
      class="bday-fries" />
    <div class="wing-alert-modal--inner">
      <ng-container *ngIf="!birthdayUpdated">
        <h5>Want a free gift on your birthday?</h5>
        <p>
          Tell us your birthday and get a free gift when it's time to celebrate!
        </p>
      </ng-container>
      <ng-container *ngIf="birthdayUpdated">
        <h5 class="">Your Birthday has been added to your account!</h5>
      </ng-container>

      <div class="d-flex flex-sm-column flex-lg-row row justify-content-center">
        <form
          [formGroup]="birthdayForm"
          class="text-left col-12 birthday-Form form-group mb-2"
          *ngIf="!birthdayUpdated">
          <label for="birthday">Enter Birthday</label>
          <input
            id="birthday"
            formControlName="birthday"
            type="tel"
            maxlength="5"
            class="form-control"
            placeholder="MM/DD"
            (keyup)="reformatBday($event)"
            onkeypress="return event.charCode > 47 && event.charCode < 58;" />
        </form>
        <div
          class="invalid-feedback"
          aria-live="polite"
          tabindex="0"
          *ngIf="birthdayForm.controls['birthday'].errors">
          <div data-cy="dobError">Enter a valid date</div>
        </div>
        <ng-container *ngIf="!birthdayUpdated">
          <button
            type="button"
            class="btn btn-outline mr-lg-2 mb-lg-0 mb-2"
            (click)="close()">
            No Thanks
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="
              !birthdayForm.valid || !birthdayForm.get('birthday').value
            "
            (click)="updateBirthday()">
            Add Your Birthday
          </button>
        </ng-container>
        <ng-container *ngIf="birthdayUpdated">
          <button
            type="button"
            class="btn btn-outline mr-lg-2 mb-lg-0 mb-2"
            (click)="close()">
            Thanks!
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="viewAccountInfo()">
            View Account Info
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
