
import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
} from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@wingstop/models/location/location.model';
import { GlobalService } from '@wingstop/services/global.services';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { Subscription } from 'rxjs';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { CommonModule } from '@angular/common';

declare var $: any;

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class NotFoundComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];
    public loading: boolean = true;
    public externalFile: string = '';
    public dynamicHtml: SafeHtml = '';
    public routesArray: any[] = [];
    private hasRedirect: boolean = false;
    public selectedLocation: Location;
    public s3SeoMetadata: SeoMetadata[];
    public dynamicHtmlDirLocation: string = '//content.wingstop.com/content/';

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        private titleService: Title,
        private metaService: Meta,
        private appStateActions: AppStateActions,
        private appStateSelectors: AppStateSelectors,
        private globalService: GlobalService
    ) { }

    ngOnDestroy() { }

    ngOnInit() {
        this.appStateSelectors.s3SeoMetadata.subscribe(
            (seoMetadata: SeoMetadata[]) => {
                this.s3SeoMetadata = seoMetadata;
            }
        );

        this.getRoutes().subscribe((data: any) => {
            this.routesArray = data['routes'];
            let currentPath = this.router.url.split('?')[0];
            this.routesArray.forEach((route) => {
                if (route['path'] === currentPath) {
                    if (route['redirect']) {
                        window.location = route['redirect'];
                        this.hasRedirect = true;
                    } else {
                        this.externalFile = route['file'];
                    }

                    if (
                        route['title'] &&
                        this.s3SeoMetadata &&
                        !this.s3SeoMetadata.some(
                            (item) => item.route && currentPath.includes(item.route)
                        )
                    ) {
                        this.titleService.setTitle(route['title']);
                    }
                    if (route['meta'] && route['meta'].length > 0) {
                        route['meta'].forEach((meta: any) => {
                            this.metaService.addTag(meta);
                        });
                    }
                }
            });

            // Mapping isn't found in the routes array
            if (!this.routesArray.find((r) => r.path === currentPath)) {
                this.titleService.setTitle('Page Not Found');
            }

            if (!this.externalFile && !this.hasRedirect) {
                this.loading = false;
                this.cd.detectChanges();
            } else if (this.externalFile) {
                this.getHtml().subscribe((html: string) => {
                    this.dynamicHtml = this.domSanitizer.bypassSecurityTrustHtml(html);
                    setTimeout(() => {
                        this.setupLinks();
                    }, 0);
                    this.loading = false;
                    this.cd.detectChanges();
                });
            }
        });
    }

    // Setup dyanmic html content to be able to handle internal and external links
    private setupLinks() {
        const links = document
            .getElementById('main-content')
            ?.getElementsByTagName('a');
        for (const link of links) {
            link.addEventListener('click', (e: MouseEvent) => {
                e.preventDefault();
                const target = <HTMLAnchorElement>e.currentTarget;
                const clickedElement = e.target as HTMLAnchorElement;

                if (clickedElement.id === "location-info-address-change") {
                    return;
                }

                // if the anchor in this link is trying to take us away from this domain
                if (target.hostname !== document.location.hostname) {
                    // display modal confirmation
                    this.globalService.externalSite(this.appStateActions, e);
                } else {
                    // Open the internal link
                    this.router.navigate([target.pathname]);
                }
            });
        }
    }

    getRoutes() {
        return this.httpClient.get(
            this.dynamicHtmlDirLocation + '_router.json?v=' + Date.now()
        );
    }

    getHtml() {
        return this.httpClient.get(
            this.dynamicHtmlDirLocation + this.externalFile,
            {
                responseType: 'text',
            }
        );
    }
}
