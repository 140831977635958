import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faXTwitter,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { environment } from '@wingstop/environments/environment';
import { GlobalService } from '@wingstop/services/global.services';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  icons = {
    faFacebookF,
    faInstagram,
    faXTwitter,
    faSpotify,
    faTiktok
  };

  public language = 'English';
  public copyrightYear: string;
  public digitalMenu = false;
  public kiosk = false;
  private subscriptions: Subscription[] = [];
  private ngUnsubscribe: Subject<boolean> = new Subject();
  public shouldShow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  legacy_base_url: string = environment.legacy.webAppBaseUrl;

  constructor(
    private appStateActions: AppStateActions,
    private appStateSelectors: AppStateSelectors,
    private router: Router
  ) {
    const params = new URL(window.location.href).searchParams;
    this.kiosk = !!params.get('kiosk');
    this.copyrightYear = GlobalService.getNow().format('YYYY');

    this.subscriptions.push(
      this.appStateSelectors.isDigitalMenu
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((isDigitalMenu: boolean) => {
          this.digitalMenu = isDigitalMenu;
          this.shouldShow$.next(!this.kiosk && !this.digitalMenu);
        })
    );
  }

  ngOnInit() { }

  // public setLanguage(lang: string) {
  //   this.language = lang;
  // }

  public setLanguage(langCode: string, langLabel: string) {
    this.language = langLabel;
  }

  public launchSpanish() {
    window.open('http://es.wingstop.com', '_blank');
  }

  public externalSite(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();

    this.appStateActions.openAlertModalWith(
      'External Site',
      'You are opening an external site',
      'Ok',
      null,
      'Cancel',
      true,
      false,
      (result: string) => {
        if (result === 'ok') {
          if (whereTo) {
            window.open(whereTo);
          } else {
            let target = <HTMLAnchorElement>event.target;
            window.open(target.href);
          }
        }
      }
    );
  }
}
